@media (min-width: 1000px) and (max-width: 1500px) {
  .panel .description {
    top: 30%;
  }
  
  .navbar1 {
    top: 0;
    height: 70px;
  }

  .title-left {
    font-size: 70px;
    margin-bottom: 0px;
    width: 100%;
  }

  .desc-left {
    font-size: 20px;
    top: 85%;
    left: 0%;
    width: 100%;
    font-weight: 700;
    letter-spacing: unset;
  }

  .title-right {
    font-size: 3rem;
    margin-left: 7.5%;
    width: 90%;
    margin-bottom: 0px;
  }

  .desc-right {
    font-size: 22px;
    right: 10%;
    width: 75%;
    top: 80%;
  }
  
  .panel .img-left {
    width: 35%;
    top: 49%;
    left: 60%;
  }

  .panel .img-right {
    width: 33%;
    top: 57%;
    left: 40%;
  }

  .skills-title {
    text-align: center;
    font-size: 3.8rem;
    font-family: Josefin Sans;
    letter-spacing: 15px;
    font-weight: 500;
    width: 100%;
    padding-top: 130px;
    /* color: #52b69a; */
    margin-bottom: 10px;
    margin-top: 100px;
    border-top: 1px solid #0003;
    background: linear-gradient(to bottom, #036666, #007f5f, #eee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .swiper-container {
    width: 60%;
    /* border-radius: 6px; */
    background: linear-gradient(to bottom, #2f2f2f66, #9f9f9f33, #fff 90%);
    cursor: grab;
    /* padding-bottom: 30px !important; */
    margin-bottom: 100px;
  }

  .line {
    width: 30%;
    background: #999;
    height: 2px;
    position: absolute;
    left: 34.5%;
  }

  .icons-div {
    margin: 0 80px 100px;
  }
}

@media (max-width: 1500px) {
  .skills-desc {
    margin: 26px 10% 60px;
  }
  .projects-desc {
    margin: 30px 10% 60px;
  }
}
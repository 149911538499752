.project-top-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #0003;
  position: relative;
}

.projects-title {
  text-align: center;
  font-size: 4rem;
  font-family: Josefin Sans;
  letter-spacing: 13px;
  font-weight: 400;
  /* border-top: 1px solid #0005; */
  /* width: 80%; */
  padding-top: 20px;
  color: #3b3b3b;
  cursor: default;
  margin-bottom: 20px;
  margin-top: 100px;
  background: linear-gradient(to bottom, #000, #000, #fff 98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line {
  width: 400px;
  background: #999;
  height: 2px;
  position: absolute;
  left: 34.5%;
  top: 11.6rem;
}

.appear {
  animation: appear 4s infinite ease-out;
}

@keyframes appear {
  0%,
  20% {
    left: -50%;
    height: 3px;
    background: #999;
  }
  40%,
  70% {
    left: 40.5%;
    height: 3px;
    background: #999;
  }
  80%,
  95% {
    left: 43%;
    height: 3px;
    background: #999;
  }
  100% {
    left: -50%;
    height: 3px;
    background: #999;
  }
}

.projects-desc {
  font-size: 30px;
  text-align: center;
  letter-spacing: 1px;
  font-family: overpass;
  margin: 30px 20rem 60px;
  color: #555;
}

.slider-img {
  width: 100%;
  /* cursor: grab; */
  padding: 40px 60px 10px 20px;
  /* border-bottom: 1px solid #0003; */
}

.swiper-wrapper {
  margin-bottom: 2em;
}

.swiper-container {
  width: 57%;
  /* border-radius: 6px; */
  background: linear-gradient(to bottom, #2f2f2f66, #9f9f9f33, #fff 90%);
  cursor: grab;
  /* padding-bottom: 30px !important; */
  margin-bottom: 100px;
}

.swiper-container:active {
  cursor: grabbing;
}

.swiper-slide {
  padding-left: 40px;
}

.swiper-button-prev {
  color: #444 !important;
  transition: 0.3s;
  padding: 35px 20px !important;
  border-radius: 2px;
}
.swiper-button-prev:hover {
  background: #0009;
  color: #eee !important;
}
.swiper-button-next {
  color: #444 !important;
  transition: 0.3s;
  padding: 35px 20px !important;
  border-radius: 2px;
}
.swiper-button-next:hover {
  background: #0009;
  color: #eee !important;
}

.swiper-pagination .swiper-pagination-bullet-active {
  /* color: #32967a; */
  background-color: #32967a;
  opacity: 1 !important;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  /* color: #32967a; */
  /* background-color: #32967a; */
  /* margin-bottom: 0px !important; */
}

.swiper-button-disabled {
  display: none !important;
}

.gridlife {
  font-size: 1.6rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 2px 2px rgba(127, 214, 159, 0.739);
  letter-spacing: 0px;
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #000;
}

.nytimes {
  font-size: 1.6rem;
  font-family: "Times New Roman";
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #000;
}

.cardo {
  font-size: 1.6rem;
  font-family: "cormorant garamond";
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #000;
}


.spamazon {
  font-size: 1.6rem;
  font-family: merienda one;
  font-weight: 300;
  margin: 0 0 10px 0;
  color: #28aac4;
}

.myhome {
  font-size: 1.5rem;
  font-family: montserrat;
  font-weight: 300;
  margin: 0 0 10px 0;
}

.portfolio {
  font-size: 1.8rem;
  font-family: josefin sans;
  margin: 0 0 10px 0;
  background: linear-gradient(to bottom, #7ae572, #7af5b2, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 1px #4443;
}

.reviewt {
  color: #282828;
  text-shadow: 0 0 5px yellow;
  font-family: caveat;
  font-weight: 500;
  /* background: #282828; */
  font-size: 2.3rem;
  margin: 0 0 10px 0;
}

.enough {
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0 0 15px 0;
  font-family: acme;
  color: rgb(46, 187, 95);
  text-shadow: 3px 3px 6px #0003;
}

.average {
  font-size: 1.8rem;
  font-family: yeseva one;
  font-weight: 300;
  margin: 0 0 10px 0;
  color: #000;
}

.project-desc {
  text-align: center;
  font-size: 20px;
  width: 80%;
  margin: 0;
  font-weight: 500;
  color: #444;
  letter-spacing: 2px;
}

.languages-used {
  color: #0008;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  font-size: 1.2rem;
}
.languages-used > i {
  margin: 0 10px;
  animation: none !important;
  font-size: 1.3rem !important;
}

.project-links-div {
  background-color: #3f3f3f;
  width: 40%;
  position: absolute;
  top: -20px;
  left: 30%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 50px 50px;
  transition: 0.3s ease-in-out;
}

.links-down {
  top: -20px;
  transition: 0.3s ease-in-out;
}

.project-links-div > a {
  color: #ddd;
  text-decoration: none;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 500;
  transition: 0.3s;
  font-family: Oswald;
}

.project-links-div > a:hover {
  color: #fff;
}

.broken-warning {
  color: #000;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 17px;
  margin: 40px 0 0;
  background-color: rgb(255, 114, 63);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}
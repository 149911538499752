/* Reset. */
* {
  box-sizing: border-box;
}

.flick-on,
.flick-off {
  position: absolute;
  right: 114%;
  border: none;
  background: none;
  color: #eee;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
  letter-spacing: 1px;
  display: none;
}
.flick-on:hover,
.flick-off:hover {
  color: #fff;
  letter-spacing: 2px;
  right: 113%;
}

.flick-on {
  display: none;
}

.title-left {
  font-size: 90px;
  font-family: josefin sans;
  width: 80%;
  color: #eee;
  text-align: center;
  text-shadow: 2px 2px 10px #0006;
  margin-bottom: 50px;
  /* margin-right: 10px; */
  white-space: nowrap;
}
.flicker {
  /* animation: flicker 5s linear infinite; */
}

@keyframes flicker {
  0%,
  5%,
  18%,
  20%,
  25%,
  50.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  95% {
    color: #0004;
    text-shadow: none;
  }
  5.1%,
  18.1%,
  20.1%,
  30%,
  50%,
  60.1%,
  65%,
  80.1%,
  90%,
  92.1%,
  100% {
    color: #fff;
    text-shadow: 0 0 10px #ffba08, 0 0 20px #ffba08, 0 0 40px #ffba08,
      0 0 160px #ffba08;
  }
}

.desc-left {
  position: absolute;
  top: 70%;
  left: 3%;
  font-size: 27px;
  text-align: center;
  width: 70%;
  color: #555;
  /* text-shadow: 2px 3px 2px #3c64; */
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0;
  margin-top: 20px;

  p {
    padding: 0;
    margin: 6px 0;  
    padding-bottom: 2px;
    list-style: none;
  }
}

.title-right {
  font-size: 60px;
  font-family: Oswald;
  margin-left: 13%;
  width: 100%;
  color: #444;
  font-weight: 500;
  letter-spacing: 4px;
  /* text-shadow: 0 0 20px #25a18e; */
}

.desc-right {
  font-size: 28px;
  text-align: center;
  right: 7%;
  position: absolute;
  top: 65%;
  width: 60%;
  color: #5f5f5f;
  font-weight: 400;
}

/* Panels. */
.splitview {
  position: relative;
  width: 100%;
  min-height: 45vw;
  overflow: hidden;
}

.panel {
  position: absolute;
  width: 100vw;
  min-height: 45vw;
  overflow: hidden;
}

.panel .content {
  position: absolute;
  width: 100vw;
  min-height: 45vw;
  color: #fff;
}

.panel .description {
  width: 50%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  text-align: center;
}

.panel .img-right {
  width: 35%;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.panel .img-left {
  width: 35%;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom {
  background-color: rgb(50, 50, 50);
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    #52b69a,
    #99d98c,
    rgb(255, 255, 255) 75%
  );
}

.bottom .description {
  right: 0%;
}

.top {
  background-color: rgb(200, 200, 200);
  z-index: 2;
  width: 50vw;
  background-image: linear-gradient(
    to bottom,
    #52b69a,
    #99d98c,
    rgb(255, 255, 255) 75%
  );

  /*-webkit-clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);*/
}

.top .description {
  left: 0%;
}

/* Handle. */
.handle {
  height: 100%;
  position: absolute;
  display: block;
  background-color: #184e7755;
  width: 5px;
  top: 0;
  left: 50%;
  z-index: 3;
}

/* Skewed. */
.skewed .handle {
  top: 50%;
  transform: rotate(30deg) translateY(-50%);
  height: 200%;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}

.skewed .top {
  transform: skew(-30deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);
}

.skewed .top .content {
  transform: skew(30deg);
  margin-left: 1000px;
}

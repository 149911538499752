.skills-container {
  /* background-color: #0008; */
  /* margin-top: 70px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.skills-title {
  text-align: center;
  font-size: 3.8rem;
  font-family: Josefin Sans;
  letter-spacing: 15px;
  font-weight: 500;
  padding-top: 20px;
  /* color: #52b69a; */
  margin: 100px 0 10px;
  background: linear-gradient(to bottom, #036666, #007f5f, #fff 98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: 4px 4px 7px #0004; */
  /* -webkit-text-stroke: 1px #0002; */
}

.skills-desc {
  font-size: 26px;
  text-align: center;
  letter-spacing: 2px;
  font-family: overpass;
  margin: 26px 20rem 60px;
  color: #555;
}

.icons-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* background: #0003; */
  margin: 0 200px;
  align-items: center;
  margin-bottom: 100px;
}

.icons-div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icons-div > div > i {
  margin: 35px;
  padding-bottom: 10px;
  z-index: 1;
  background: #fff;
  width: 100px;
  text-align: center;
  /* color: #69a95c; */
}

.icon {
  border-bottom: 1px solid #0000;
  transition: 0.5s ease-in-out;
}

.icon-line {
  border-bottom: 1px solid #ddd;
  transition: 0.3s ease-in-out;
}

.icon-name {
  font-size: 20px;
  position: absolute;
  margin-top: 100px;
  color: #777;
  /* color: #0000; */
  opacity: 0;
  transition: 0.3s opacity, 0.5s margin ease-in-out;
  font-weight: 300;
}

.slide {
  opacity: 1;
  margin-top: 170px;
  transition: 1s opacity, 0.3s margin ease-in-out;
}

.devicon-javascript-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(237, 218, 28, 0.8); */
}
.devicon-javascript-plain:hover {
  /* color: rgb(237, 218, 28); */
  animation: bounce 0.8s infinite;
}

.devicon-html5-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(231, 96, 45, 0.8); */
}
.devicon-html5-plain:hover {
  /* color: rgb(231, 96, 45); */
  animation: bounce 0.8s infinite;
}

.devicon-css3-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(37, 63, 221, 0.8); */
}
.devicon-css3-plain:hover {
  /* color: rgb(37, 63, 221); */
  animation: bounce 0.8s infinite;
}

.devicon-tailwindcss-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
}
.devicon-tailwindcss-plain:hover {
  animation: bounce 0.8s infinite;
}

.devicon-jquery-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(22, 100, 168, 0.8); */
}
.devicon-jquery-plain:hover {
  /* color: rgb(22, 100, 168); */
  animation: bounce 0.8s infinite;
}

.devicon-react-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(99, 221, 243, 0.8); */
}
.devicon-react-original:hover {
  /* color: rgb(99, 221, 243); */
  animation: bounce 0.8s infinite;
}

.devicon-typescript-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
}
.devicon-typescript-original:hover {
  animation: bounce 0.8s infinite;
}

.devicon-nextjs-line {
  font-size: 100px;
  transition: 0.17s ease-in-out;
}

.devicon-nextjs-line:hover {
  animation: bounce 0.8s infinite;
}

.devicon-redux-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(88, 55, 136, 0.8); */
}
.devicon-redux-original:hover {
  /* color: rgb(88, 55, 136); */
  animation: bounce 0.8s infinite;
}

.devicon-sass-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(197, 94, 148, 0.8); */
}
.devicon-sass-original:hover {
  /* color: rgb(197, 94, 148); */
  animation: bounce 0.8s infinite;
}

.devicon-bootstrap-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(110, 0, 239, 0.8); */
}
.devicon-bootstrap-plain:hover {
  /* color: rgb(110, 0, 239); */
  animation: bounce 0.8s infinite;
}

.devicon-python-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(426, 190, 49, 0.8); */
}
.devicon-python-plain:hover {
  /* color: rgb(426, 190, 49); */
  animation: bounce 0.8s infinite;
}

.devicon-django-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(7, 87, 61, 0.8); */
}
.devicon-django-plain:hover {
  /* color: rgb(7, 87, 61); */
  animation: bounce 0.8s infinite;
}

.devicon-nodejs-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(130, 203, 41, 0.8); */
}
.devicon-nodejs-plain:hover {
  /* color: rgb(130, 203, 41); */
  animation: bounce 0.8s infinite;
}

.devicon-express-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(47, 47, 47, 0.8); */
}
.devicon-express-original:hover {
  /* color: rgb(47, 47, 47); */
  animation: bounce 0.8s infinite;
}

.devicon-firebase-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(235, 127, 20, 0.8); */
}
.devicon-firebase-plain:hover {
  /* color: rgb(235, 127, 20); */
  animation: bounce 0.8s infinite;
}

.devicon-mongodb-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(67, 154, 55, 0.8); */
}
.devicon-mongodb-plain:hover {
  /* color: rgb(67, 154, 55); */
  animation: bounce 0.8s infinite;
}

.devicon-postgresql-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(51, 99, 141, 0.8); */
}
.devicon-postgresql-plain:hover {
  /* color: rgb(51, 99, 141); */
  animation: bounce 0.8s infinite;
}

.devicon-bash-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(41, 48, 54, 0.8); */
}
.devicon-bash-plain:hover {
  /* color: rgb(41, 48, 54); */
  animation: bounce 0.8s infinite;
}

.devicon-git-plain {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(231, 71, 50, 0.8); */
}
.devicon-git-plain:hover {
  /* color: rgb(231, 71, 50); */
  animation: bounce 0.8s infinite;
}

.devicon-github-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(22, 20, 20, 0.8); */
}
.devicon-github-original:hover {
  /* color: rgb(22, 20, 20); */
  animation: bounce 0.8s infinite;
}

.devicon-npm-original-wordmark {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(195, 45, 54, 0.8); */
}
.devicon-npm-original-wordmark:hover {
  /* color: rgb(195, 45, 54); */
  animation: bounce 0.8s infinite;
}

.devicon-heroku-original {
  font-size: 100px;
  transition: 0.17s ease-in-out;
  /* color: rgba(95, 0, 171, 0.8); */
}
.devicon-heroku-original:hover {
  /* color: rgb(95, 0, 171); */
  animation: bounce 0.8s infinite;
}

@keyframes bounce {
  0% {
    padding-bottom: 10px;
  }

  50% {
    margin-top: 15px;
    padding-bottom: 30px;
  }
}

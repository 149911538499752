.mobile-croll {
  --webkit-overflow-scrolling: touch;
}

.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

* {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
  /* background: #f3f3f3; */
}

/* ================= NAVBAR ================== */

.navbar1 {
  height: 80px;
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 4;
  transition: 0.5s;
}

.navbar2 {
  height: 65px;
  position: fixed;
  top: -90px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 4;
  transition: 0.5s;
  background: linear-gradient(to left, #036666, #358f80, #469d89, #99e2b4, #fff 100%);
  box-shadow: 0 0 20px #0004;
}

.nav-links {
  position: absolute;
  right: 0;
  margin-right: 30px;
}

.gold-link1 {
  color: #ffba08 !important;
  background: #0003;
  padding: 5px 12px !important;
  border-radius: 3px;
  margin: 0 5px;
}

.gold-link1:hover {
  background: #0005 !important;
}

.nav-links > a {
  text-decoration: none;
  color: #fff9;
  padding: 0 12px;
  font-weight: 700;
  font-size: 17px;
  transition: 0.31s;
}

.nav-links > a:hover {
  color: #fff;
}

.about-link {
  color: #fff !important;
}

.navbar2 > div > a {
  text-decoration: none;
  color: #fff9;
  padding: 0 12px;
  font-weight: 700;
  font-size: 17px;
  transition: 0.3s ease-in-out;
}
.navbar2 > div > a:hover {
  color: #fff !important;
}

/* .projects-link {
  background: #0003;
  padding: 5px 12px !important;
  border-radius: 3px;
  margin: 0 5px;
}

.projects-link:hover {
  background: #0005 !important;
} */

.fa-chevron-up {
  color: #fff;
  position: fixed;
  right: -52px;
  bottom: 20px;
  font-size: 2rem;
  background: #0005;
  border-radius: 50px;
  padding: 10px 12px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.fa-chevron-up:hover {
  background: #0009;
}

.show-arrow {
  right: 12px;
  opacity: 1;
}

/* ================= FOOTER ================== */

.footer {
  /* height: 100px; */
  box-shadow: 0 0 30px #3332;
}

.footer-title {
  font-family: montserrat;
  font-weight: 300;
  color: #333;
  font-size: 1.1rem;
  text-align: center;
  padding: 30px 0;
}

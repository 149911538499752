@media (max-width: 1000px) {
  .panel .description {
    top: 40%;
  }

  .title-left {
    font-size: 2.5rem;
    margin: 0;
    width: 100%;
  }

  .desc-left {
    font-size: 1rem;
    top: 70%;
    left: 0%;
    width: 100%;
    font-weight: 700;
    letter-spacing: unset;
  }

  .desc-left > p {
    font-size: .9rem;
    padding: 0;
    margin: 2px 10px;
  }

  .title-right {
    font-size: 1.9rem;
    margin-left: 0%;
    width: 90%;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  .desc-right {
    font-size: 1.1rem;
    text-align: center;
    right: 11%;
    position: absolute;
    top: 65%;
    width: 90%;
    color: #5f5f5f;
    font-weight: 500;
  }

  .panel .img-left {
    width: 35%;
    top: 49%;
    left: 60%;
  }

  .panel .img-right {
    width: 35%;
    top: 58%;
    left: 30%;
  }

  .icons-div {
    margin: 0 80px 100px;
  }

  .line {
    width: 50%;
  }

  .swiper-container {
    width: 80%;
  }
}

@media (max-width: 600px) {
  body {
    margin-top: 80px;
    --webkit-overflow-scrolling: touch;
}

  /* ================== NAV BAR AND ABOUT ME ================== */

  .navbar1 {
    background: #282828;
    top: 0;
    height: 80px;
  }
  .nav-links > a {
    font-size: 11px;
    padding: 0px 7px 0 7px;
    /* margin: auto !important; */
  }

  .navbar2 {
    height: 65px;
    position: fixed;
    top: -90px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 4;
    transition: 0.5s;
    background: #036666;
    box-shadow: 0 0 20px #0004;
    display: none;
  }

  .fa-chevron-up {
    /* right: 12px !important; */
    /* opacity: 1 !important; */
    z-index: 9;
  }

  .navbar2 > div > a {
    padding: 0 10px;
    font-weight: 900;
    font-size: 14px;
  }

  .flick-on,
  .flick-off {
    position: absolute;
    right: 35%;
    font-size: 13px;
    bottom: 113%;
    background: #999;
    padding: 8px;
    color: #333;
    display: none !important;
  }

  .flick-on:hover,
  .flick-off:hover {
    color: #333;
    letter-spacing: 1px;
    right: 35%;
  }

  .panel .description {
    top: 30%;
  }

  .title-left {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .desc-left {
    margin-top: 5px;
    p {
      font-size: .7rem;
      margin: 0 10px;
    }

    p:before {
      content: "•";
      padding-right: 10px;
      color: #38383877;
    }
  }

  .title-right {
    font-size: 17px;
  }

  .desc-right {
    font-size: 13px;
    right: 11%;
    top: 50%;
  }

  .panel .img-left {
    width: 40%;
    top: 52%;
    left: 64%;
  }

  /* ================== SKILLS ================== */

  .skills-title {
    font-size: 2.8rem;
    width: 70%;
    margin-bottom: 0px;
    margin-top: 40px;
  }

  .skills-desc {
    font-size: 20px;
    width: 93%;
    letter-spacing: 1px;
    margin-bottom: 40px !important;
    margin-top: 20px !important;
  }

  .icons-div {
    margin: 0px 10px;
    margin-bottom: 50px;
  }

  .icons-div > div > i {
    margin: 20px 20px 0px 20px;
    padding-bottom: 25px;
    font-size: 4.5rem;
    animation: none !important;
  }

  .icon-name {
    display: none;
  }

  #icon-line {
    display: none;
  }

  /* PROJECTS */

  .projects-title {
    font-size: 2.8rem;
  }

  .line {
    width: 60%;
    left: 34.5%;
  }

  .appear {
    animation: appear 4s infinite ease-out;
  }

  @keyframes appear {
    0%,
    20% {
      left: -80%;
      height: 3px;
      background: #999;
    }
    40%,
    70% {
      left: 18%;
      height: 3px;
      background: #999;
    }
    80%,
    95% {
      left: 37%;
      height: 3px;
      background: #999;
    }
    100% {
      left: -50%;
      height: 3px;
      background: #999;
    }
  }

  .projects-desc {
    font-size: 20px;
    width: 92%;
  }

  .slider-img {
    width: 110%;
    /* cursor: grab; */
    padding: 40px 40px 20px 0px;
    /* border-bottom: 1px solid #0003; */
  }

  .swiper-container {
    width: 95%;
    margin-bottom: 50px;
  }

  .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next {
    display: none !important;
  }

  .splitview,
  .panel,
  .panel .content
   {
    min-height: 55vw;
  }

  .project-desc {
    text-align: center;
    font-size: 18px;
    width: 100%;
    margin: 0;
    margin-right: 30px;
    font-weight: 500;
    color: #444;
    letter-spacing: 1px;
  }

  .languages-used {
    font-size: 1.1rem;
    margin-right: 15px;
  }

  .languages-used > i {
    font-size: 1.1rem !important;
  }

  .project-links-div {
    background-color: #3f3f3f;
    width: 80%;
    position: absolute;
    top: -20px;
    left: 10%;
    height: 50px;
  }

  .project-links-div > a {
    color: #ddd;
    text-decoration: none;
    padding: 0 30px;
    font-size: 20px;
    font-weight: 500;
    transition: 0.3s;
    font-family: Oswald;
  }

  /* RESUME */

  .resume-title {
    font-size: 2.8rem;
    margin-bottom: 50px;
    margin-top: 70px;
    padding: 0 20px;
  }

  .resume-div {
    padding-bottom: 20px;
  }

  .resume-desc {
    margin-left: 0;
  }

  .why-hire-me {
    width: 90%;
    margin-bottom: 50px;
  }

  .resume-photo {
    width: 75%;
  }

  .view-button {
    padding: 17px 40px;
    margin-right: 0px;
    background: #16899a;
    font-size: 1.1rem;
  }

  .resume-modal-content {
    width: 95%;
  }

  .resume-modal-photo {
    padding: 30px 0 30px 0;
    width: 95%;
    height: 580px;
  }

  .close-resume-modal {
    font-size: 25px;
    margin-top: 14  px;
    background-color: #b8b8b877;
  }

  .modal-header > h2 {
    text-align: center;
    font-size: 1.4rem;
    font-family: Josefin Sans;
    font-weight: 400;
    margin: 25px 0 20px;
  }

  /* CONTACT */

  #contact {
    padding-top: 50px !important;
    margin-bottom: 100px;
  }

  .contact-letters {
    margin: 0 10px;
    font-size: 2rem;
    margin-bottom: 40px !important;
  }

  .letter {
    height: 25px;
    width: 20px;
    border-radius: 50px;
    margin: 0 10px;
    border: 2.8px solid rgb(148, 89, 37);
  }

  #contact-m-letter {
    margin-left: 30px !important;
  }

  @keyframes span {
    0%,
    30% {
      margin: 0 10px;
    }
    70%,
    100% {
      margin: 0 5px;
    }
  }
  @keyframes letter {
    0%,
    30% {
      width: 25px;
      border: 2.5px solid rgb(148, 89, 37);
    }
    70%,
    100% {
      width: 14vw;
      border: 2.5px solid rgb(148, 89, 37);
    }
  }

  .contact-container {
    max-width: 400px;
  }

  .tel-link {
    display: none;
  }

  .linkedin-link {
    display: none;
  }
  .email-link {
    display: none;
  }
  .github-link {
    display: none;
  }

  .tel-mobile {
    display: block;
    cursor: pointer !important;
  }

  .linkedin-mobile {
    display: block;
    cursor: pointer !important;
  }
  .email-mobile {
    display: block;
    cursor: pointer !important;
  }
  .github-mobile {
    display: block;
    cursor: pointer !important;
  }
}

@media (max-width: 400px) {
  .title-left {
    margin-bottom: 20px;
  }

  .desc-left {
    top: 50%;

    p {
      font-size: .6rem !important;
    }
  }

  .title-right {
    margin-bottom: 33px;
  }

  .desc-right {
    top: 40%;
  }

  .nav-links {
    margin-right: 15px;
  }

  .nav-links > a {
    padding: 0px 4px;
  }

  .gold-link1 {
    padding: 5px !important;
  }
}
